const data = [
  {
    id: 1,
    title: "SPECIALS",
    desc: (
      <p>
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ut quas
        
      </p>
    ),
  },
  {
    id: 2,
    title: "SPECIALS",
    desc: (
      <p>
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ut quas
        
      </p>
    ),
  },
  {
    id: 3,
    title: "SPECIALS",
    desc: (
      <p>
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ut quas
        
      </p>
    ),
  },

  {
    id: 4,
    title: "SPECIALS",
    desc: (
      <p>
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ut quas
       
      </p>
    ),
  },
];

export default data;
